import { Controller } from "@hotwired/stimulus";

// Allows modifying a link based on parameters.
// Parameters should be inputs.
// Link should contain a `href` attribute.
export default class extends Controller {
  static targets = ["parameter", "link"];

  linkTargetConnected(target) {
    target.addEventListener("click", this.handleLinkClicked.bind(this));
  }

  handleLinkClicked(event) {
    let link = new URL(event.target.href);

    this.parameterTargets.forEach((param) => {
      if (param.name && param.value) {
        link.searchParams.append(param.name, param.value);
      }
    });

    event.target.href = link.toString();
  }
}
