const LOCALE_FOR_PRICE = "nl-NL";
const TYPE_KEY = "type";
const PRICE_KEY = "price";
const DESCRIPTION_KEY = "description";

export const SourceMenuMixin = (Base) =>
  class extends Base {
    pushSourceMenu() {
      this.sourceMappings().forEach((sourceMapping) => {
        this.performSourceMapping(sourceMapping);
      });
    }
    performSourceMapping(sourceMapping) {
      const templateField = this.templateFieldFromName(sourceMapping.target);
      if (!templateField) return;

      console.group(`Mapping categories to ${sourceMapping.target}`);

      const targetRows = JSON.parse(templateField.value);
      const blueprint = this.blueprintFromRows(targetRows);

      sourceMapping.source.forEach((category) => {
        const sourceItems = this.sourceMenuData().filter(
          (item) => item.category === category,
        );
        sourceItems.forEach((menuItem) => {
          let row = targetRows.find((row) => row.name === menuItem.name);
          if (!row) {
            row = { ...blueprint };
            targetRows.push(row);
          }

          this.mapRowWithTypeEquivalence(row, menuItem);
        });
      });

      this.editor.api.setFormFieldValue(
        sourceMapping.target,
        JSON.stringify(targetRows),
      );

      console.groupEnd();
    }

    // Returns a blueprint for a new row, based on existing rows.
    // Currently, this just gets the last row and clears all of its attributes.
    blueprintFromRows(rows) {
      const row = { ...rows[rows.length - 1] };

      // clear all values in the object
      for (const key in row) {
        const value = row[key];
        if (key === TYPE_KEY) {
          // Type should always be the same as the last row, so we do nothing.
          break;
        } else if (typeof value === "boolean") {
          row[key] = false;
        } else if (typeof value === "number" && !Number.isNaN(value)) {
          if (!Number.isInteger(value)) {
            // float
            row[key] = 0.0;
          } else {
            // regular integer
            row[key] = 0;
          }
        } else {
          // assume stringish
          row[key] = "";
        }
      }
      return row;
    }

    // At this point the row is fully ready from a template item.
    // We need to map the menu item onto it, using the template's values as a guide.
    mapRowWithTypeEquivalence(printessItem, sourceMenuItem) {
      for (const attr in printessItem) {
        const printessItemValue = printessItem[attr];

        // convert the value to the type of the template value
        if (attr === TYPE_KEY) {
          // We want to keep the original type, so we do nothing here.
          continue;
        } else if (attr === PRICE_KEY) {
          // Prices should be strings.
          printessItem[attr] = this.formatPrice(sourceMenuItem[attr]);
          // if it is not empty
          // format it to 2 DP and comma.
        } else if (attr === DESCRIPTION_KEY) {
          // because description is a reservered keyword in the json schema.
          printessItem[attr] = sourceMenuItem['item_description'];
        } else if (typeof printessItemValue === "boolean") {
          printessItem[attr] = this.formatBoolean(sourceMenuItem[attr]);
        } else if (typeof printessItemValue === "number" && !Number.isNaN(printessItemValue)) {
          if (!Number.isInteger(printessItemValue)) {
            printessItem[attr] = this.formatFloat(sourceMenuItem[attr]);
          } else {
            printessItem[attr] = this.formatInt(sourceMenuItem[attr]);
          }
        } else {
          // just copy it verbatim
          printessItem[attr] = sourceMenuItem[attr];
        }
      }
    }

    formatInt(int) {
      return parseInt(int) || 0;
    }

    formatFloat(float) {
      return parseFloat(float) || 0.0;
    }

    formatPrice(price) {
      if (!price) {
        return null;
      }

      const num = parseFloat(price);

      // TODO: We probably want this settable via metadata. It dictates the price format.
      const userLocale = LOCALE_FOR_PRICE;

      // Format the number to 2 decimal places using the user's locale
      return num.toLocaleString(userLocale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    formatBoolean(boolean) {
      return !!boolean;
    }

    // Customer's source menu
    // e.g.
    // {
    //   "beer": [
    //     { name: "Beer 1", price: 1.00 },
    //     { name: "Beer 2", price: 2.00 },
    //   ]
    // }
    sourceMenuData() {
      if (!this.sourceMenuDataValue) return {};

      return JSON.parse(this.sourceMenuDataValue);
    }

    // Which categories of the source menu to put in the form field.
    // e.g.
    // [
    //   { "source": "alcoholic_drinks", "target": ["beer", "wine", "beer_on_tap"] },
    //   { "source", "non_alcoholic_drinks": "target": ["soda", "water"] },
    // ]
    sourceMappings() {
      return this.metadata("source_mappings") || [];
    }
  };
