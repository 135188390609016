import { flashError } from "@/utils/flash.js";
import Sentry from "@/sentry.js";

const AUTOSAVE_INTERVAL = 20000; // ms;
const METADATA_FIELD_NAME = "metadata";

export const BaseMixin = (Base) =>
  class extends Base {
    connect() {
      this.enrichSentryContext();
      this.enableSaveOnSubmit();
      this.loadPrintessEditor();
    }

    enrichSentryContext() {
      Sentry.setContext("editor", {
        kind: "printess",
        template: this.templateName(),
      });
    }

    async loadPrintessEditor() {
      const printessLoader = await import(
        "https://editor.printess.com/printess-editor/loader.js"
      );

      this.editor = await printessLoader.load({
        token: this.shopTokenValue,
        templateName: this.templateName(),
        container: this.containerTarget,
        theme: "app.menuez.nl",
        basketId: this.basketIdValue,
        addToBasketCallback: this.addToBasketCallback.bind(this),
      });

      this.pushSourceMenu();
      this.pushTextVariables();
      this.uploadLogo();
      this.pushCss();
      this.attachAutoSaver();
    }

    // Form fields from the template
    templateFields() {
      if (!this.formFieldsValue) return [];

      return JSON.parse(this.formFieldsValue);
    }

    templateFieldFromName(name) {
      return this.templateFields().find((f) => f.name === name);
    }

    // Metadata from the template — a special field that we use to store
    // useful things
    metadata(key = null) {
      const metadataField = this.templateFields().find(
        (f) => f.name === METADATA_FIELD_NAME,
      );
      if (!metadataField) return {};

      const data = JSON.parse(metadataField.value);

      if (key) {
        return data[key];
      }

      return data;
    }

    // Template will be the template name given if the template was never saved.
    // After customisation, though, it's the save token.
    templateName() {
      if (this.hasSaveToken()) {
        return this.saveTokenInputTarget.value;
      }

      return this.templateNameValue;
    }

    isSaveInProgress() {
      return this.formSubmitButtonTarget.disabled;
    }

    hasUnsavedChanges() {
      return this.editor.api.hasUnsavedChanges();
    }

    disableManualSave() {
      this.formSubmitButtonTarget.disabled = true;
    }

    enableManualSave() {
      this.formSubmitButtonTarget.disabled = false;
    }

    // Make sure we regularly save @ printess and mirror the token @ menuez
    // So that a user will not lose their work in the event of something going wrong.
    attachAutoSaver() {
      setInterval(async () => {
        if (this.isSaveInProgress()) {
          console.debug("Autosave skipped because form is already saving.");
          return;
        }

        if (!this.hasUnsavedChanges()) {
          console.debug("Autosave skipped because no changes were found.");
          return;
        }

        console.debug("Autosaving.");

        this.disableManualSave();

        const token = await this.editor.api.save();
        this.updateSaveToken(token);
        await this.pushNewSaveTokenToMenuez(token);

        this.enableManualSave();
      }, AUTOSAVE_INTERVAL);
    }

    updateSaveToken(token) {
      console.debug(`Updated save token: ${token}`);

      this.updateSaveTokenInputTarget(token);
    }

    async addToBasketCallback(saveToken, _thumbnailUrl) {
      this.updateSaveToken(saveToken);
      this.jumpToAfterSaveTarget();
    }

    updateSaveTokenInputTarget(saveToken) {
      this.saveTokenInputTarget.value = saveToken;
      this.saveTokenInputTarget.disabled = false;
    }

    async pushNewSaveTokenToMenuez(token) {
      try {
        await fetch(this.editorUpdateEndpointValue, {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            product_personalization: { printess_save_token: token },
          }),
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }

    jumpToAfterSaveTarget() {
      const target = document.querySelector(this.afterSaveTargetSelectorValue);

      if (!target) return;

      target.scrollIntoView({ behavior: "smooth" });
    }

    enableSaveOnSubmit() {
      this.element.addEventListener("submit", (event) => {
        this.saveAndSubmit(event);
      });
    }

    async saveAndSubmit(event) {
      event.preventDefault();

      this.ensureCommitInputIsPresent(event);

      // if we have a save token and there's nothing new to save, just submit the form and continue
      // with the usual menuez flow.
      if (this.hasSaveToken() && !this.hasUnsavedChanges()) {
        return this.element.submit();
      }

      // otherwise, validate and try to save
      await this.validateAndSave(event);
    }

    async validateAndSave(saveEvent) {
      const errors = this.editor.api.validate();
      console.debug(errors);

      if (errors.length === 0) {
        const token = await this.editor.api.save();

        if (token.length === 0) {
          flashError(
            "Something went wrong when attempting to save the design. Please try again.",
            { scrollTo: true },
          );
          return saveEvent.stopPropagation();
        } else {
          this.updateSaveToken(token);
          this.element.submit();
        }
      } else {
        // there are errors
        flashError("Validatie mislukt. Voltooi alle stappen.", {
          scrollTo: true,
        });

        this.resetEditor();

        return saveEvent.stopPropagation();
      }
    }

    // ProductPersonalizations#update has different routes to follow based on params[:commit]
    ensureCommitInputIsPresent(event) {
      if (
        event.submitter &&
        event.submitter.name &&
        event.submitter.name == "commit" &&
        event.submitter.value
      ) {
        let commit_input = this.element.querySelector(
          "input[type=hidden][name=commit]",
        );
        if (commit_input) {
          return;
        }

        commit_input = document.createElement("input");
        commit_input.name = "commit";
        commit_input.type = "hidden";
        commit_input.value = event.submitter.value;

        this.element.appendChild(commit_input);
      }
    }

    hasSaveToken() {
      return this.saveTokenInputTarget.value.length > 0;
    }

    resetEditor() {
      this.editor.api.clearSelection();
      this.editor.api.gotoFirstStep();
    }
  };
