import { Controller } from "@hotwired/stimulus";

// Custom file input yay
// Optionally has an image target, which will preview
// an image if an image is selected.
export default class extends Controller {
  static targets = ["input", "label", "previewTemplate", "previewContainer"];

  inputTargetConnected(target) {
    target.addEventListener("change", this.inputChanged.bind(this));
  }

  inputChanged(event) {
    this.updateLabelFromInput()
    this.clearPreviews()
    this.showPreviews()
  }

  clearPreviews() {
     this.previewContainerTarget.innerHTML = ""
  }

  showPreviews() {
    [... this.inputTarget.files].map(file => {
      this.showPreview(file)
    })
  }

  showPreview(file) {
    if (!file.type.startsWith("image/")) return

    const reader = new FileReader()
    const preview = this.cloneTemplate()

    reader.onload = (event) => {
      preview.querySelector("img").src = event.target.result

      this.previewContainerTarget.appendChild(preview)
    }

    reader.readAsDataURL(file)
  }

  cloneTemplate() {
    return this.previewTemplateTarget.content.cloneNode(true)
  }

  updateLabelFromInput() {
    if (!this.hasLabelTarget) return
    this.labelTarget.textContent = [... this.inputTarget.files].map(file => file.name)
  }
}
